.settings-modal {
    width: 60% !important; 
}

.settings-modal h2, h3, p {
    color: #ffffff;
}
  
.settings-modal .ant-modal-content {
    color: #ffffff !important;
    background-color: #1d1c1c !important;
    border: none !important;
}

.settings-modal .ant-modal-header {
    background-color: #1d1c1c !important;
    border: none !important;
}

.settings-modal .ant-modal-title {
    color: #ffffff !important;
}

.settings-modal .ant-modal-close-x {
    color: #ffffff !important;
}

.settings-modal .ant-modal-footer {
    display: none;
}

.settings-modal .ant-menu {
    background-color: #1d1c1c !important;
    color: #ffffff !important;
}

.settings-modal .ant-menu-item {
    color: #ffffff !important;
    background-color: #1d1c1c !important;
}

.settings-modal .ant-menu-item:hover {
    color: #1E97C2 !important;
}
  
.settings-modal .ant-menu-item-selected {
    color: #1E97C2 !important;
}

.custom-checkbox .ant-checkbox {
    .ant-checkbox-inner {
        background-color: #272727;
        border-color: #272727;
    }
    &.ant-checkbox-checked .ant-checkbox-inner {
        background-color: #1E97C2; // Cor de fundo do checkbox quando selecionado
        border-color: #1E97C2; // Cor da borda do checkbox quando selecionado
        color: #ffffff;
    }
}

.settings-container {
    display: flex;
    justify-content:flex-start;
    padding: 1rem;
}

.general-settings {
    display: 'flex';
    align-items: left;
    border-right: solid #333;
    padding: 10px;
}

