.error-modal {
    color: #ffffff !important; 
}
  
.error-modal .ant-modal-content {
    color: #ffffff !important;
    background-color: #1d1c1c !important;
    border: none !important;
}

.error-modal .ant-modal-header {
    background-color: #1d1c1c !important;
    border: none !important;
}

.error-modal .ant-modal-title {
    color: #ffffff !important;
}

.error-modal .ant-modal-close-x {
    color: #ffffff !important;
}

.error-modal .ant-modal-footer {
    border: none !important;
    padding: 1rem;
}
  