.table-select-inWell .ant-table-thead > tr > th {
  background: #1D1C1C;
};

.table-select-inWell .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  color: #e6f7ff;
}

.ant-table-tbody > tr.ant-table-row-selected td {
  &:hover {
    background: rgb(49, 48, 48);
  }
  background: rgb(49, 48, 48);
}

.table-select-inWell .ant-table-thead > tr:hover.ant-table-row-selected > td, 
.table-select-inWell .ant-table-tbody > tr:hover.ant-table-row-selected > td {
  background: #1D1C1C;
}

// .ant-table-tbody > tr.ant-table-row-selected {
//   &:hover {
//     background: rgb(49, 48, 48);
//   }
//   background: rgb(49, 48, 48);
// }

.table-select-inWell .ant-table-row .ant-table-row-level-0 {
  color: #e6f7ff;
}

.table-select-inWell .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #333;
}

.table-select-inWell .ant-table-tbody > tr > td {
  border: none
}

.table-select-inWell .ant-table-column-title {
  color: #737C80;
}

.table-select-inWell .ant-table-tbody {
  color: #e6f7ff;
}

.table-select-inWell .ant-table-row .ant-table-row-level-0 a {
  color: #e6f7ff
}

.table-select-inWell .ant-table-tbody > tr > td a {
  color: #e6f7ff;
}

.selectModal-inWell-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #e6f7ff;
}

.selectModal-inWell-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #e6f7ff;
}

.selectModal-inWell-steps .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #e6f7ff;
}

.cuttoff-load-container {
  width: 100%;
  height: 100%;
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-direction: column;
}