/*********************
    Experiment
*********************/

.experiment-container .ant-tabs-nav-wrap .ant-tabs-tab {
  color: #fff !important;
}

.experiment-container .ant-tabs-tabpane {
  display: flex !important;
  justify-content: center !important;
}

.experiments {

  & .ant-collapse>.ant-collapse-item,
  & .ant-collapse-icon-position-right {
    border-bottom: none !important;
    border: 0;
    // padding-bottom: 0.3rem;
  }

  & .ant-collapse-item {
    // background: #1E1E1E !important;
    color: #fff;
    margin-bottom: 14px;
  }

  & .ant-collapse-header {
    color: #fff !important;
    text-transform: uppercase;
  }

  & .ant-collapse-content .ant-collapse-content-active {
    background: #1d1c1c;
    color: #fff;
  }
}

.modal-machine-learning {
  & .spinner--small {
    border: 4px solid #ccc;
    border-left-color: #1E1E1E !important;
  }
}


/*********************
       Models
*********************/
.output_curve_input .ant-input{
  background-color: #333 !important;
}

.container-card-model {
  display: flex;
  justify-content: space-evenly;
}

.ant-collapse-icon-position-right>.ant-collapse-item-active>.ant-collapse-header{
  border-bottom: 10px solid rgb(32, 37, 38);

}
.models {

  & .ant-collapse {
    background-color: transparent;
  }

  & .ant-collapse>.ant-collapse-item,
  & .ant-collapse-icon-position-right {
    border-bottom: none !important;
    border: 0;
  }

  & .ant-collapse-item {
    background: #1E1E1E !important;
    color: #fff;
    margin-bottom: 14px;
  }

  & .ant-collapse-header {
    color: #fff !important;
    text-transform: uppercase;
  }

  & .ant-collapse-content {
    background-color: rgb(32, 37, 38);
    border: none;

    & .ant-collapse-content-active {
      background: #1d1c1c;
      color: #fff;
    }
  }

  & .ant-collapse-content-box {
    background-color: #1E1E1E;
    border: 2px solid #1E1E1E;
    border-radius: 10px;
  }
}

.modal-models {
  & .spinner--small {
    border: 4px solid #ccc;
    border-left-color: rgb(29, 28, 28) !important;
  }
}

.apply-model-button {
  position: absolute;
  right: 12px;
  top: 7px;
  color: #24FF4D;
  border: 1px solid #24FF4D;
  background-color: rgba(24, 144, 255, 0%) !important;

  &:hover {
    border-color: 1px solid #24FF4D;
  }
}

.box-card-model {
  padding: 10px;
}

.wrapper-card-model {
  padding: 24px;
  width: 360px;
  background: #1E1E1E;
}

.wrapper-card-settings {
  width: 360px;
  background: #1E1E1E;
  padding: 24px;
}

.btn-continue {
  width: 230px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.box-model-text {
  margin-bottom: 10px
}

.detail-model-title {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

.detail-model-description {
  font-size: 14px;
  color: #999999;
  padding-bottom: 10px;
}

.title-model-lithology {
  width: 240px;
  color: #fff;
  margin-left: 20px;
}

.title-model-settings {
  color: #fff;
  margin-left: 20px
}

.select-settings .ant-select-selection--single {
  //width: 220px;
  background: #333;
  color: #fff;
}

.ant-select-dropdown-menu-item,
.ant-select-dropdown-menu-item-selected {
  background: #333;
  color: #fff;
}

.select-settings {
  background-color: #333;

  .ant-select-dropdown-menu-item {
    color: #fff;
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item-selected {
    background: #333;
  }
}

.experiments-table .ant-table-tbody .experiments-table .ant-table-tbody,
.experiments-table .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  color: #ffffff !important;
  background: #1d1c1c !important;
  ;
}

.experiments-table .ant-table-thead>tr:first-child>th {
  min-width: 100px !important;
}

.select-settings {
  .ant-select-selection {
    background-color: #333;
    color: #fff;
    border-color: #333;
    border-radius: 2px;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
}

.select-dropdown-settings-models {
  background-color: #333;
  box-shadow: 0px 2px 8px #1e1e1e;

  .ant-select-dropdown-menu-item {
    color: #fff;
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item-selected {
    background-color: #333;
  }
}

.select-settings .ant-select-arrow .ant-select-arrow-icon>svg {
  fill: #fff;
}

.wrapper-message-results {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.model-result-msg-success {
  color: #fff;
  font-style: 18px;
  font-weight: bold;
}

.model-result-msg-applied {
  color: #fff;
  font-style: 14px;
}

.model-result-msg-drift {
  color: #fff;
  font-style: 14px;
  margin-top: 10px;
}

.text-model-msg-success {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-see-results {
  width: 240px;
  padding: 14px;
  border: solid 2px #40C300;
  background: #1e1e1e;
  color: #40C300;
  font-size: 12;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-see-results:hover {
  width: 240px;
  padding: 14px;
  border: solid 2px #40C300;
  background: #1e1e1e;
  color: #40C300;
  font-size: 12;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-see-more{
  &:hover {
    background-color: #5d00ff33;
  }
}